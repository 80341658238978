import { Edit, SimpleForm, TextInput } from "react-admin";

const EditCommentTitle = () => {
  return <span>Edit Comment</span>;
};

export const CommentEdit = () => (
  <Edit title={<EditCommentTitle />}>
    <SimpleForm>
      <TextInput source="content" fullWidth multiline />
    </SimpleForm>
  </Edit>
);
