import {
  FileField,
  DateInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  FileInput,
  SelectInput,
  required,
  ImageInput,
  ImageField,
  useGetList,
  FormDataConsumer,
  BooleanInput,
} from "react-admin";
import { Grid } from "@mui/material";
import { getYesterdayAsString, getCurrentDateAsString } from "../../Helpers";

const JobFamilyInput = (props) => {
  const { data, isLoading } = useGetList("jobFamilies", {}, {});
  const choices = data !== null && data !== undefined ? data : [];
  return (
    <SelectInput
      source="jobFamily"
      choices={choices}
      disabled={isLoading}
      {...props}
    />
  );
};

const JobLevelInput = (props) => {
  const { data, isLoading } = useGetList("jobLevels", {}, {});
  const choices = data !== null && data !== undefined ? data : [];
  return (
    <SelectInput
      source="jobLevel"
      choices={choices}
      disabled={isLoading}
      {...props}
    />
  );
};

const EnglishInput = (props) => {
  const { data, isLoading } = useGetList(
    "english",
    { sort: { name: "ASC" } },
    {}
  );
  const choices = data !== null && data !== undefined ? data : [];
  return (
    <SelectInput
      source="english"
      choices={choices}
      disabled={isLoading}
      {...props}
    />
  );
};

const CurrencyInput = (props) => {
  const { data, isLoading } = useGetList("currencies", {}, {});
  const choices = data !== null && data !== undefined ? data : [];
  return (
    <SelectInput
      source="grossSalaryCurrency"
      choices={choices}
      disabled={isLoading}
      {...props}
    />
  );
};

const SourceInput = (props) => {
  const { data, isLoading } = useGetList("sources", {}, {});
  const choices = data !== null && data !== undefined ? data : [];
  return (
    <SelectInput
      source="source"
      choices={choices}
      disabled={isLoading}
      {...props}
    />
  );
};

const GenderInput = (props) => {
  const { data, isLoading } = useGetList("genders", {}, {});
  const choices = data !== null && data !== undefined ? data : [];
  return (
    <SelectInput
      source="gender"
      choices={choices}
      disabled={isLoading}
      {...props}
    />
  );
};

export const CandidateCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" fullWidth validate={required()} />
      <TextInput source="email" fullWidth validate={required()} />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextInput source="alias" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <GenderInput fullWidth validate={required()} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <SourceInput fullWidth />
        </Grid>
        <Grid item xs={6}>
          <NumberInput source="experience" fullWidth min={0} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextInput source="lastEmployer" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextInput source="prevEmployer" fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <NumberInput source="grossSalary" fullWidth min={0} />
        </Grid>
        <Grid item xs={6}>
          <CurrencyInput fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <JobFamilyInput fullWidth />
        </Grid>
        <Grid item xs={6}>
          <JobLevelInput fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <EnglishInput fullWidth />
        </Grid>
        <Grid item xs={6}>
          {/* <NumberInput source="onboardRate" fullWidth /> */}
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <DateInput
            source="availableFrom"
            fullWidth
            inputProps={{
              min: getCurrentDateAsString(),
            }}
            validate={required()}
          />
        </Grid>
        <Grid item xs={6}>
          <DateInput
            source="birthday"
            fullWidth
            inputProps={{
              max: getYesterdayAsString(),
            }}
          />
        </Grid>
      </Grid>

      <ImageInput source="avatar" label="Avatar" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <BooleanInput source="isCvDirect" label="Is CV a direct link?" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const isCvDirect = formData.isCvDirect;
          return isCvDirect ? (
            <TextInput source="cvUrl" label="CV Link" fullWidth />
          ) : (
            <FileInput source="cv" label="CV">
              <FileField source="src" title="title" />
            </FileInput>
          );
        }}
      </FormDataConsumer>

      <BooleanInput
        source="isPortfolioDirect"
        label="Is Portfolio a direct link?"
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const isPortfolioDirect = formData.isPortfolioDirect;
          return isPortfolioDirect ? (
            <TextInput source="portfolioUrl" label="Portfolio Link" fullWidth />
          ) : (
            <FileInput source="portfolio" label="Portfolio">
              <FileField source="src" title="title" />
            </FileInput>
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);
