const roles = {
  // This will be the role used in Reactjs Front End
  Owner: "Owner",
  Admin: "Admin",
  HiringDirector: "Hiring Director",
  HiringManager: "Hiring Manager",
  Finance: "Finance",
  // This will be the role used in React Admin.
  SuperAdmin: "Super Admin",
};

export default roles;
