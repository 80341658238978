import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  useRecordContext,
} from "react-admin";

const EditForm = (props) => {
  const record = useRecordContext(props);
  return (
    <SimpleForm>
      {record.client !== undefined ? (
        <ReferenceInput label="Client" source="client.id" reference="clients">
          <SelectInput label="Client" optionText="name" disabled />
        </ReferenceInput>
      ) : (
        <div />
      )}
      <BooleanInput source="approved" />
      <TextInput source="note" multiline fullWidth />
    </SimpleForm>
  );
};

const EditCandidateTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.name}` : ""}</span>;
};
export const ManageEdit = (props) => {
  return (
    <Edit {...props} title={<EditCandidateTitle />}>
      <EditForm />
    </Edit>
  );
};
