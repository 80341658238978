import * as React from "react";
import { Menu } from "react-admin";

import PeopleIcon from "@mui/icons-material/People";
import ArticleIcon from "@mui/icons-material/Article";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import QuizIcon from "@mui/icons-material/Quiz";
import WorkIcon from "@mui/icons-material/Work";
import LanguageIcon from "@mui/icons-material/Language";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import BadgeIcon from "@mui/icons-material/Badge";
import PaidIcon from "@mui/icons-material/Paid";
import WcIcon from "@mui/icons-material/Wc";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SourceIcon from "@mui/icons-material/Source";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import CommentIcon from "@mui/icons-material/Comment";
import SubMenu from "./SubMenu";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import StoreIcon from "@mui/icons-material/Store";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TimerIcon from "@mui/icons-material/Timer";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";

export const MainMenu = () => (
  <Menu>
    <Menu.DashboardItem />
    <Menu.Item
      to="/requisitions"
      primaryText="Requisitions"
      leftIcon={<ArticleIcon />}
    />
    <SubMenu primaryText="Candidates" leftIcon={<GroupsIcon />}>
      <Menu.Item
        to="/nonassigned"
        primaryText="Master Candidate List"
        leftIcon={<AssignmentIndIcon />}
      />
      <Menu.Item
        to="/candidates"
        primaryText="Recruit"
        leftIcon={<MeetingRoomIcon />}
      />
      <Menu.Item to="/manages" primaryText="Manage" leftIcon={<BadgeIcon />} />
    </SubMenu>

    <Menu.Item
      to="/users"
      primaryText="Manage Users"
      leftIcon={<PeopleIcon />}
    />

    <Menu.Item
      to="/clients"
      primaryText="Manage Clients"
      leftIcon={<StoreIcon />}
    />

    <Menu.Item
      to="/comments"
      primaryText="Manage Comments"
      leftIcon={<CommentIcon />}
    />

    <Menu.Item
      to="/billings"
      primaryText="Manage Billing"
      leftIcon={<AttachMoneyIcon />}
    />

    <Menu.Item
      to="/timesheets"
      primaryText="Manage Timesheet"
      leftIcon={<TimerIcon />}
    />

    <Menu.Item
      to="/assets"
      primaryText="IT Assets"
      leftIcon={<DesktopMacIcon />}
    />

    <SubMenu primaryText="Utilities" leftIcon={<AccountBalanceIcon />}>
      <Menu.Item
        to="/rolePermissions"
        primaryText="Roles & Permissions"
        leftIcon={<LocalPoliceIcon />}
      />
      <Menu.Item
        to="/actions"
        primaryText="Actions"
        leftIcon={<CircleNotificationsIcon />}
      />
      <Menu.Item
        to="/jobFamilies"
        primaryText="Job Families"
        leftIcon={<WorkIcon />}
      />
      <Menu.Item
        to="/jobLevels"
        primaryText="Job Levels"
        leftIcon={<WorkHistoryIcon />}
      />
      <Menu.Item
        to="/skillTestings"
        primaryText="Types of Skill Testing"
        leftIcon={<QuizIcon />}
      />
      <Menu.Item
        to="/english"
        primaryText="English"
        leftIcon={<LanguageIcon />}
      />
      <Menu.Item
        to="/currencies"
        primaryText="Currency"
        leftIcon={<PaidIcon />}
      />
      <Menu.Item to="/genders" primaryText="Gender" leftIcon={<WcIcon />} />
      <Menu.Item
        to="/holidays"
        primaryText="Holiday"
        leftIcon={<CalendarTodayIcon />}
      />
      <Menu.Item to="/sources" primaryText="Source" leftIcon={<SourceIcon />} />
      <Menu.Item
        to="/logs"
        primaryText="Activity Logs"
        leftIcon={<VpnKeyIcon />}
      />
    </SubMenu>
  </Menu>
);
