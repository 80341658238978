import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const { REACT_APP_SERVER_URL: apiUrl } = process.env;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add your own headers here
  const { accessToken } = JSON.parse(localStorage.getItem("auth"));
  options.headers.set("Authorization", "Bearer " + accessToken);
  options.headers.set("Access-Control-Expose-Headers", "Content-Range");
  return fetchUtils.fetchJson(url, options);
};

function getFormDataFromParams(params) {
  var data = params.data;
  let formData = new FormData();
  for (var key in data) {
    if (key === "downloadFile") {
      if (data.downloadFile && data.downloadFile.rawFile) {
        formData.append("document", data.downloadFile.rawFile);
      }
    } else if (key === "descriptionFile") {
      if (data.descriptionFile && data.descriptionFile.rawFile) {
        formData.append("image", data.descriptionFile.rawFile);
      }
    }
    if (key === "logo") {
      if (data.logo && data.logo.rawFile) {
        formData.append("image", data.logo.rawFile);
      }
    } else if (key === "cv") {
      if (data.cv && data.cv.rawFile) {
        formData.append("cv", data.cv.rawFile);
      }
    } else if (key === "portfolio") {
      if (data.portfolio && data.portfolio.rawFile) {
        formData.append("portfolio", data.portfolio.rawFile);
      }
    } else if (key === "avatar") {
      if (data.avatar && data.avatar.rawFile) {
        formData.append("avatar", data.avatar.rawFile);
      }
    } else if (key === "client") {
      console.log(data);
      if (data.client && data.client.id) {
        formData.append("client", data.client.id);
      } else if (typeof data.client === "string") {
        formData.append("client", data.client);
      }
    } else if (key === "hiringManager" && data.hiringManager) {
      if (data.hiringManager.id) {
        formData.append("hiringManager", data.hiringManager.id);
      } else {
        formData.append("hiringManager", data.hiringManager);
      }
    } else if (
      key === "lastStep" ||
      key === "nextStep" ||
      key === "jobFamily" ||
      key === "jobLevel" ||
      key === "skillTesting" ||
      key === "actions" ||
      key === "english" ||
      key === "gender" ||
      key === "emailPermission" ||
      key === "pushNotificationPermission" ||
      key === "permissions" ||
      key === "requisitionIds" ||
      key === "requisitions" ||
      key === "currency" ||
      key === "rateMatrix"
    ) {
      if (data[key]) {
        formData.append(key, JSON.stringify(data[key]));
      }
    } else if (data[key] !== null && data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  }

  return formData;
}

const dataProvider = {
  getList: (resource, params) => {
    var query = {};
    if (params.pagination) {
      const { page, perPage } = params.pagination;
      query = {
        page,
        perPage,
      };
    }

    if (params.filter && params.filter.candidateId) {
      query["candidateId"] = params.filter.candidateId;
    }
    if (params.filter && params.filter.approved) {
      query["approved"] = params.filter.approved;
    }
    if (params.filter && params.filter.clientId) {
      query["clientId"] = params.filter.clientId;
    } else if (params.filter && params.filter.client) {
      query["clientId"] = params.filter.client;
    }

    if (params.filter && params.filter.lastStep) {
      query["lastStep"] = params.filter.lastStep;
    }

    if (
      params.filter &&
      params.filter.isAfterHired !== null &&
      params.filter.isAfterHired !== undefined
    ) {
      query["isAfterHired"] = params.filter.isAfterHired;
    }

    if (params.filter && params.filter.q) {
      query["q"] = params.filter.q;
    }

    if (params.filter && params.filter.candidateid) {
      query["candidateId"] = params.filter.candidateid;
    }

    if (params.meta && params.meta.forAdmin) {
      query["forAdmin"] = true;
    }

    if (resource === "requisitions" || resource === "candidates") {
      let selectedClient = localStorage.getItem("client");
      if (
        selectedClient !== null &&
        selectedClient !== "null" &&
        selectedClient !== ""
      ) {
        query["clientId"] = selectedClient;
      }
    }

    if (params.sort) {
      query["sortName"] = params.sort.field;
      query["sortOrder"] = params.sort.order;
    }

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total, //parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },
  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    var query = {
      filter: JSON.stringify({ ids: params.ids }),
    };

    if (params.ids.length > 0) {
      const firstVar = params.ids[0];
      if (firstVar instanceof Object && firstVar.id) {
        const filterArrays = params.ids.map((idObject) => idObject.id);
        query.filter = JSON.stringify({ ids: filterArrays });
      }
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json.data }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  create: (resource, params) => {
    const formData = getFormDataFromParams(params);
    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: formData,
    }).then(({ json }) => {
      if (resource === "requisitions/getRateForRequisition") {
        return json;
      } else {
        return {
          data: { ...params.data, id: json.id },
        };
      }
    });
  },
  update: async (resource, params) => {
    const formData = getFormDataFromParams(params);
    return httpClient(`${apiUrl}/${resource}`, {
      method: "PUT",
      body: formData,
    }).then(({ json }) => ({ data: json.data }));
  },
  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: "DELETE",
      body: JSON.stringify({ id: params.id }),
    }).then(({ json }) => ({ data: json }));
  },
  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

export default dataProvider;
