import { Edit, SimpleForm, TextInput, required } from "react-admin";

import { ToolbarWithoutDelete } from "../ToolbarWithoutDelete";

export const CurrencyEdit = () => (
  <Edit>
    <SimpleForm toolbar={<ToolbarWithoutDelete />}>
      <TextInput source="name" fullWidth validate={required()} />
      <TextInput source="symbol" fullWidth validate={required()} />
    </SimpleForm>
  </Edit>
);
