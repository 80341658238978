import { Datagrid, List, NumberField, TextField } from "react-admin";

export const SourceList = () => (
  <List sort={{ field: "name", order: "ASC" }}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <NumberField source="onboardRate" sortable={false} />
    </Datagrid>
  </List>
);
