import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  FunctionField,
  DeleteWithConfirmButton,
  EditButton,
} from "react-admin";

export const HolidayList = () => (
  <List sort={{ field: "name", order: "ASC" }}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="event" sortable={false} />
      <FunctionField
        sortable={false}
        label="Start date"
        render={(record) => record.startDay + "/" + record.startMonth}
      />
      <FunctionField
        sortable={false}
        label="End date"
        render={(record) => record.endDay + "/" + record.endMonth}
      />
      <BooleanField source="isRepeatable" />
      <EditButton />
      <DeleteWithConfirmButton
        width="100px"
        confirmTitle="Warning"
        confirmContent="Do you want to delete this holiday?"
      />
    </Datagrid>
  </List>
);
