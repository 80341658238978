import { Datagrid, List, TextField, FunctionField } from "react-admin";
import { Box } from "@mui/material";

export const RolePermissionList = () => (
  <List>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="role" />
      <FunctionField
        label="Permissions"
        source="permissions"
        render={(record) => (
          <Box
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            {`${record.permissions.join("\n") ?? ""}`}
          </Box>
        )}
      />
    </Datagrid>
  </List>
);
