import React from "react";
import { Datagrid, RecordContextProvider } from "react-admin";
import { TableCell, TableRow } from "@mui/material";

const MyDatagridRow = ({ record, id, children }) => (
  <RecordContextProvider value={record}>
    <TableRow>
      {/* data columns based on children */}
      {React.Children.map(children, (field) => (
        <TableCell
          style={{ width: field.props.width }}
          key={`${id}-${field.props.source}`}
        >
          {field}
        </TableCell>
      ))}
    </TableRow>
  </RecordContextProvider>
);

const MyDatagrid = (props) => <Datagrid {...props} row={<MyDatagridRow />} />;

export default MyDatagrid;
