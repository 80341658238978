import { Create, SimpleForm, TextInput, required } from "react-admin";

export const JobLevelCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" fullWidth validate={required()} />
      <TextInput source="abbr" fullWidth />
    </SimpleForm>
  </Create>
);
