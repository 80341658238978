import {
  Admin,
  Resource,
  CustomRoutes,
} from "react-admin";
import dataProvider from "./providers/dataProvider";
import authProvider from "./providers/authProvider";
import { UserList } from "./pages/User/UserList";
import { ClientList } from "./pages/Client/ClientList";
import { ClientCreate } from "./pages/Client/ClientCreate";
import { ClientEdit } from "./pages/Client/ClientEdit";
import { UserCreate } from "./pages/User/UserCreate";
import { UserEdit } from "./pages/User/UserEdit";
import { RequisitionList } from "./pages/Requisition/RequisitionList";
import { RequisitionCreate } from "./pages/Requisition/RequisitionCreate";
import { RequisitionEdit } from "./pages/Requisition/RequisitionEdit";
import { CandidateList } from "./pages/Candidate/CandidateList";
import { CandidateEdit } from "./pages/Candidate/CandidateEdit";
import { CandidateCreate } from "./pages/Candidate/CandidateCreate";
import { CandidateAssignClient } from "./pages/Candidate/CandidateAssignClient";
import { CandidateSetStatus } from "./pages/Candidate/CandidateSetStatus";
import { JobFamilyEdit } from "./pages/JobFamily/JobFamilyEdit";
import { JobFamilyList } from "./pages/JobFamily/JobFamilyList";
import { JobFamilyCreate } from "./pages/JobFamily/JobFamilyCreate";
import { JobLevelCreate } from "./pages/JobLevel/JobLevelCreate";
import { JobLevelEdit } from "./pages/JobLevel/JobLevelEdit";
import { JobLevelList } from "./pages/JobLevel/JobLevelList";
import { SkillTestingCreate } from "./pages/SkillTesting/SkillTestingCreate";
import { SkillTestingEdit } from "./pages/SkillTesting/SkillTestingEdit";
import { SkillTestingList } from "./pages/SkillTesting/SkillTestingList";
import { EnglishList } from "./pages/English/EnglishList";
import { EnglishEdit } from "./pages/English/EnglishEdit";
import { EnglishCreate } from "./pages/English/EnglishCreate";
import { GenderCreate } from "./pages/Gender/GenderCreate";
import { GenderEdit } from "./pages/Gender/GenderEdit";
import { GenderList } from "./pages/Gender/GenderList";
import { ActionList } from "./pages/Action/ActionList";
import { ActionEdit } from "./pages/Action/ActionEdit";
import { ActionCreate } from "./pages/Action/ActionCreate";
import { CurrencyList } from "./pages/Currency/CurrencyList";
import { CurrencyCreate } from "./pages/Currency/CurrencyCreate";
import { CurrencyEdit } from "./pages/Currency/CurrencyEdit";
import { ManageList } from "./pages/Manage/ManageList";
import { ManageEdit } from "./pages/Manage/ManageEdit";
import { HolidayList } from "./pages/Holiday/HolidayList";
import { HolidayCreate } from "./pages/Holiday/HolidayCreate";
import { HolidayEdit } from "./pages/Holiday/HolidayEdit";
import { SourceList } from "./pages/Source/SourceList";
import { SourceCreate } from "./pages/Source/SourceCreate";
import { SourceEdit } from "./pages/Source/SourceEdit";
import { ManageSetStatus } from "./pages/Manage/ManageSetStatus";
import { NonAssignedList } from "./pages/NonAssigned/NonAssignedList";
import { Dashboard } from "./pages/Dashboard";
import { LogList } from "./pages/Log/LogList";
import { CommentList } from "./pages/Comment/CommentList";
import { CommentEdit } from "./pages/Comment/CommentEdit";
import { RolePermissionList } from "./pages/RolePermissions/RolePermissionsList";
import { RolePermissionsCreate } from "./pages/RolePermissions/RolePermissionsCreate";
import { RolePermissionsEdit } from "./pages/RolePermissions/RolePermissionsEdit";
import { BillingList } from "./pages/Billing/BillingList";
import { BillingEdit } from "./pages/Billing/BillingEdit";
import { BillingCreate } from "./pages/Billing/BillingCreate";
import { TimesheetList } from "./pages/Timesheet/TimesheetList";
import { TimesheetCreate } from "./pages/Timesheet/TimesheetCreate";
import { TimesheetEdit } from "./pages/Timesheet/TimesheetEdit";
import { AssetList } from "./pages/Asset/AssetList";
import { AssetCreate } from "./pages/Asset/AssetCreate";
import { AssetEdit } from "./pages/Asset/AssetEdit";
import { Route } from "react-router-dom";

import { MyLayout } from "./menu/MyLayout";

function App() {
  return (
    <Admin
      requireAuth
      layout={MyLayout}
      authProvider={authProvider}
      dataProvider={dataProvider}
      dashboard={Dashboard}
    >
      <Resource
        name="clients"
        list={ClientList}
        create={ClientCreate}
        edit={ClientEdit}
      />
      <Resource
        name="users"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
      />
      <Resource
        name="requisitions"
        list={RequisitionList}
        create={RequisitionCreate}
        edit={RequisitionEdit}
      />
      <Resource
        name="candidates"
        list={CandidateList}
        // create={CandidateCreate}
        edit={CandidateEdit}
      />
      <Resource
        name="nonassigned"
        list={NonAssignedList}
        create={CandidateCreate}
        edit={CandidateEdit}
      />
      <Resource
        name="actions"
        list={ActionList}
        create={ActionCreate}
        edit={ActionEdit}
      />
      <Resource
        name="jobFamilies"
        list={JobFamilyList}
        create={JobFamilyCreate}
        edit={JobFamilyEdit}
      />
      <Resource
        name="jobLevels"
        list={JobLevelList}
        create={JobLevelCreate}
        edit={JobLevelEdit}
      />
      <Resource
        name="skillTestings"
        list={SkillTestingList}
        create={SkillTestingCreate}
        edit={SkillTestingEdit}
      />
      <Resource
        name="english"
        list={EnglishList}
        create={EnglishCreate}
        edit={EnglishEdit}
      />
      <Resource
        name="currencies"
        list={CurrencyList}
        create={CurrencyCreate}
        edit={CurrencyEdit}
      />
      <Resource
        name="genders"
        list={GenderList}
        create={GenderCreate}
        edit={GenderEdit}
      />
      <Resource name="manages" list={ManageList} edit={ManageEdit} />
      <Resource
        name="holidays"
        list={HolidayList}
        create={HolidayCreate}
        edit={HolidayEdit}
      />
      <Resource
        name="sources"
        list={SourceList}
        create={SourceCreate}
        edit={SourceEdit}
      />
      <Resource name="logs" list={LogList} />
      <Resource name="comments" list={CommentList} edit={CommentEdit} />
      <Resource
        name="rolePermissions"
        create={RolePermissionsCreate}
        list={RolePermissionList}
        edit={RolePermissionsEdit}
      />
      <Resource
        name="billings"
        list={BillingList}
        edit={BillingEdit}
        create={BillingCreate}
      />
      <Resource
        name="timesheets"
        list={TimesheetList}
        create={TimesheetCreate}
        edit={TimesheetEdit}
      />
      <Resource
        name="assets"
        list={AssetList}
        create={AssetCreate}
        edit={AssetEdit}
      />
      <CustomRoutes>
        <Route
          path="candidate/assignClient/:id/:candidateid"
          element={<CandidateAssignClient />}
        />
        <Route
          path="candidate/setStatus/:id/:laststepid"
          element={<CandidateSetStatus />}
        />
        <Route
          path="manage/setStatus/:id/:laststepid"
          element={<ManageSetStatus />}
        />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
