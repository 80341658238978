import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  useRecordContext,
  FormDataConsumer,
} from "react-admin";
import { Grid } from "@mui/material";
import {
  getYesterdayAsString,
  getCurrentDateAsString,
  dateFormatter,
} from "../../Helpers";

const JobFamilyInput = (props) => {
  return (
    <ReferenceInput reference="jobFamilies" source="jobFamily">
      <SelectInput
        label="Job Family"
        source="jobFamily"
        optionText="name"
        defaultValue=""
        {...props}
      />
    </ReferenceInput>
  );
};

const JobLevelInput = (props) => {
  return (
    <ReferenceInput reference="jobLevels" source="jobLevel">
      <SelectInput
        label="Job Level"
        source="jobLevel"
        optionText="name"
        defaultValue=""
        {...props}
      />
    </ReferenceInput>
  );
};

const SourceInput = (props) => {
  return (
    <ReferenceInput reference="sources" source="source">
      <SelectInput
        label="Source"
        source="source"
        optionText="name"
        defaultValue=""
        {...props}
      />
    </ReferenceInput>
  );
};

const EnglishInput = (props) => {
  return (
    <ReferenceInput
      reference="english"
      source="english"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput
        label="English"
        source="english"
        optionText="name"
        defaultValue=""
        {...props}
      />
    </ReferenceInput>
  );
};

const CurrencyInput = (props) => {
  return (
    <ReferenceInput reference="currencies" source={props.source}>
      <SelectInput
        label="Currency"
        source={props.source}
        optionText="name"
        defaultValue=""
        {...props}
      />
    </ReferenceInput>
  );
};

const GenderInput = (props) => {
  return (
    <ReferenceInput reference="genders" source="gender" label="Gender">
      <SelectInput
        // label="Gender"
        source="gender"
        optionText="name"
        {...props}
      />
    </ReferenceInput>
  );
};

const EditForm = (props) => {
  const record = useRecordContext(props);
  return (
    <SimpleForm>
      {record.client !== undefined ? (
        <ReferenceInput label="Client" source="client.id" reference="clients">
          <SelectInput label="Client" optionText="name" disabled />
        </ReferenceInput>
      ) : (
        <div />
      )}
      {record.client === undefined ? (
        <Grid container spacing={1}>
          <TextInput source="name" fullWidth validate={required()} />
          <TextInput source="email" fullWidth validate={required()} />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextInput source="alias" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <GenderInput fullWidth validate={required()} />
              {/* <TextInput source="sex" fullWidth /> */}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div />
      )}

      {record.client !== undefined ? (
        <></>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <SourceInput fullWidth />
          </Grid>
          <Grid item xs={6}>
            <NumberInput source="experience" fullWidth min={0} />
          </Grid>
        </Grid>
      )}

      {record.client !== undefined ? (
        <></>
      ) : (
        <Grid container spacing={1}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextInput source="lastEmployer" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextInput source="prevEmployer" fullWidth />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <NumberInput source="grossSalary" fullWidth min={0} />
            </Grid>
            <Grid item xs={6}>
              <CurrencyInput source="grossSalaryCurrency" fullWidth />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <JobFamilyInput fullWidth />
            </Grid>
            <Grid item xs={6}>
              <JobLevelInput fullWidth />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <EnglishInput fullWidth />
            </Grid>
            <Grid item xs={6}>
              {/* <NumberInput source="onboardRate" fullWidth /> */}
            </Grid>
          </Grid>
        </Grid>
      )}

      {record.client !== undefined ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <NumberInput
                source="rate"
                fullWidth
                validate={required()}
                min={0}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <CurrencyInput
                source="currency"
                fullWidth
                validate={required()}
                disabled
              />
            </Grid>
          </Grid>
          <BooleanInput source="approved" />
          <TextInput source="note" multiline fullWidth />
        </>
      ) : (
        <Grid container spacing={1}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DateInput
                source="availableFrom"
                format={dateFormatter}
                fullWidth
                validate={required()}
                inputProps={{
                  min: getCurrentDateAsString(),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DateInput
                source="birthday"
                fullWidth
                format={dateFormatter}
                inputProps={{
                  max: getYesterdayAsString(),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {record.client === undefined ? (
        <div>
          <ImageInput source="avatar" label="Avatar" accept="image/*" fullWidth>
            <ImageField source="src" title="title" />
          </ImageInput>
          <BooleanInput source="isCvDirect" label="Is CV a direct link?" />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const isCvDirect = formData.isCvDirect;
              return isCvDirect ? (
                <TextInput source="cvUrl" label="CV Link" fullWidth />
              ) : (
                <FileInput source="cv" label="CV">
                  <FileField source="src" title="title" />
                </FileInput>
              );
            }}
          </FormDataConsumer>

          <BooleanInput
            source="isPortfolioDirect"
            label="Is Portfolio a direct link?"
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const isPortfolioDirect = formData.isPortfolioDirect;
              return isPortfolioDirect ? (
                <TextInput
                  source="portfolioUrl"
                  label="Portfolio Link"
                  fullWidth
                />
              ) : (
                <FileInput source="portfolio" label="Portfolio">
                  <FileField source="src" title="title" />
                </FileInput>
              );
            }}
          </FormDataConsumer>
        </div>
      ) : (
        <div />
      )}
    </SimpleForm>
  );
};

const EditCandidateTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.name}` : ""}</span>;
};

export const CandidateEdit = (props) => {
  const transform = (data) => {
    data.tempDescriptionText = "";
    data.tempCurrencySymbol = "";
    data.tempSource = "";
    data.tempEnglish = "";
    return data;
  };
  return (
    <Edit {...props} title={<EditCandidateTitle />} transform={transform}>
      <EditForm />
    </Edit>
  );
};
