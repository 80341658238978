import {
  DateField,
  List,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  useGetList,
  SelectInput,
  Filter,
  FunctionField,
  TextInput,
  BooleanField,
} from "react-admin";
import { useMemo, useEffect, useState } from "react";
import AssignClientButton from "./AssignClientButton";
import SetStatusButton from "./SetStatusButton";
import MyDatagrid from "../MyDatagrid";
import {
  getCurrentSelectedClientName,
  getDisplayCandidateInfo,
  getDisplayCandidateRate,
  getDisplayCandidateGrossSalary,
} from "../../Helpers";
import { Box } from "@mui/material";
import CircleImageField from "../CircleImageField";
import dataProvider from "../../providers/dataProvider";
import { useLogout } from "react-admin";

const ClientFilters = (props) => {
  const [actions, setActions] = useState([]);
  const { data, isLoading } = useGetList("clients");
  const memoizedData = useMemo(() => data ?? [], [data]);
  const logout = useLogout();
  useEffect(() => {
    const filterInfo = { filter: { isAfterHired: false } };
    dataProvider
      .getList("actions", filterInfo)
      .then((data) => {
        setActions(data.data);
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  }, []);
  return (
    <Filter {...props}>
      <SelectInput
        source="client"
        choices={memoizedData}
        isLoading={isLoading}
        alwaysOn
      />
      <SelectInput source="lastStep" choices={actions} alwaysOn />
      <TextInput label="Search by name" source="q" alwaysOn />
    </Filter>
  );
};

const NoteInfo = () => {
  return (
    <>
      <div>
        AKA: <TextField source="alias" label="AKA" sortable={false} />
      </div>
      <div>
        English:{" "}
        <FunctionField
          label="English"
          render={(record) => (record.english ? record.english.name : "_")}
          sortable={false}
        />
      </div>
      <div>
        Gross Salary:{" "}
        <FunctionField
          sortable={false}
          label="GrossSalary"
          render={(record) =>
            getDisplayCandidateGrossSalary(record.grossSalary, record)
          }
        />
      </div>
      <div>
        Source:{" "}
        <FunctionField
          label="Source"
          render={(record) => (record.source ? record.source.name : "_")}
          sortable={false}
        />{" "}
      </div>
      <div>
        Onboard Rate:{" "}
        <FunctionField
          label="Onboard Rate"
          render={(record) =>
            record.source ? record.source.onboardRate ?? "_" : "_"
          }
          sortable={false}
        />
      </div>
      <div>
        Note:{" "}
        <FunctionField
          label="Note"
          render={(record) =>
            record.note && record.note !== "" ? (
              <TextField source="note" component="pre" />
            ) : (
              "_"
            )
          }
          sortable={false}
        />
      </div>
    </>
  );
};

const MyDataGridValue = (props) => {
  return (
    <MyDatagrid bulkActionButtons={false} {...props}>
      <CircleImageField
        source="avatar.src"
        title="Avatar"
        width="40px"
        label="Avatar"
        sortable={false}
      />
      <FunctionField
        label="Name"
        source="name"
        render={(record) => (
          <Box
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            {`${record.name} \n`}
            <span style={{ fontSize: "11px" }}>
              {getDisplayCandidateInfo(record)}
            </span>
          </Box>
        )}
      />

      <TextField source="gender.name" label="Gender" sortable={false} />

      <DateField
        source="availableFrom"
        sortable={false}
        options={{ timeZone: "UTC" }}
      />
      <FunctionField
        sortable={false}
        label="Rate"
        render={(record) => getDisplayCandidateRate(record.rate, record)}
      />

      <FunctionField
        label="Client name"
        render={(record) => (record.client ? record.client.name : "_")}
        sortable={false}
      />

      <FunctionField
        sortable={false}
        label="Last Step"
        render={(record) => (record.lastStep ? record.lastStep.name : "_")}
      />

      <FunctionField
        sortable={false}
        label="Next Key Date"
        render={(record) =>
          record.nextStepDate ? (
            <DateField source="nextStepDate" options={{ timeZone: "UTC" }} />
          ) : (
            "_"
          )
        }
      />
      <FunctionField
        sortable={false}
        label="Next Step"
        render={(record) => (record.nextStep ? record.nextStep.name : "_")}
      />

      <FunctionField
        label="Approved"
        render={(record) =>
          record.client !== undefined ? (
            <BooleanField source="approved" />
          ) : (
            <></>
          )
        }
      />
      <EditButton />
      <FunctionField
        render={(record) =>
          record.client === undefined ? (
            <>
              <AssignClientButton
                record={{ record }}
                disabled={record.alreadyHired}
              />
            </>
          ) : (
            <>
              <SetStatusButton record={{ record }} />
              <DeleteWithConfirmButton
                width="100px"
                confirmTitle="Warning"
                confirmContent="Do you want to delete this candidate?"
              />
            </>
          )
        }
      />
    </MyDatagrid>
  );
};

const CandidateTitle = () => {
  var clientName = "Candidate list";
  var selectedClientName = getCurrentSelectedClientName();
  if (selectedClientName !== "") {
    clientName += " filtered by " + selectedClientName;
  }
  return <span>{clientName}</span>;
};

// const candidateFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const CandidateList = () => {
  return (
    <List
      title={<CandidateTitle />}
      filters={<ClientFilters />}
      sort={{ field: "name", order: "ASC" }}
    >
      <MyDataGridValue expand={<NoteInfo />} isRowExpandable={(row) => true} />
    </List>
  );
};
