import { Button } from "react-admin";
import { Link } from "react-router-dom";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";

const AssignClientButton = ({ record, disabled }) => {
  return (
    <Button
      component={Link}
      to={`/candidate/assignClient/${record.record.id}/${record.record.candidateId}`}
      label={disabled ? "Already Hired" : "Assign to client"}
      disabled={disabled}
    >
      <AddBusinessIcon />
    </Button>
  );
};

export default AssignClientButton;
