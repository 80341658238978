import {
  SimpleForm,
  required,
  BooleanInput,
  SelectInput,
  DateInput,
} from "react-admin";
import { useParams } from "react-router-dom";
import dataProvider from "../../providers/dataProvider";
import { useState, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useLogout } from "react-admin";

const toChoices = (items) => {
  return items.map((item) => {
    return {
      id: item.id,
      name: item.name,
    };
  });
};

const ActionInput = (props) => {
  let { laststepid, source, candidateid } = props;
  const { setValue } = useFormContext();

  const [choices, setChoices] = useState([]);
  const logout = useLogout();
  useEffect(() => {
    dataProvider
      .getList("actions", { filter: { candidateid } })
      .then((data) => {
        setChoices(toChoices(data.data));
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  }, [candidateid]);

  useEffect(() => {
    if (choices.length > 0 && laststepid) {
      setValue(source, laststepid);
    }
  }, [choices, laststepid, setValue, source]);

  return <SelectInput choices={choices} {...props} />;
};

const NextActionInput = (props) => {
  const result = useWatch({ name: "result" });
  let { candidateid } = props;
  const logout = useLogout();
  const [choices, setChoices] = useState([]);
  useEffect(() => {
    dataProvider
      .getList("actions", { filter: { candidateid } })
      .then((data) => {
        setChoices(toChoices(data.data));
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  }, [candidateid]);

  return result ? <SelectInput choices={choices} {...props} /> : <div />;
};

const NextActionDateInput = (props) => {
  const result = useWatch({ name: "result" });
  return result ? <DateInput {...props} /> : <div />;
};

const validationSetStatus = (values) => {
  const errors = {};
  // if (values.result && !values.nextStep) {
  //   errors.nextStep = "Next step is required";
  // }

  // if (values.result && !values.nextStepDate) {
  //   errors.nextStepDate = "Next step date is required";
  // }

  return errors;
};

export const CandidateSetStatus = (props) => {
  let { id, laststepid } = useParams();
  const logout = useLogout();
  const postSave = (data) => {
    const postData = {
      id,
      lastStep: data.lastStep,
      result: data.result,
      nextStep: data.nextStep,
      nextStepDate: data.nextStepDate,
    };
    dataProvider
      .create("candidates/setCandidateAction", { data: postData })
      .then(() => {
        window.location.href = "#/candidates";
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  };
  return (
    <SimpleForm onSubmit={postSave} validate={validationSetStatus}>
      <ActionInput
        validate={required()}
        laststepid={laststepid}
        source="lastStep"
        candidateid={id}
        fullWidth
      />
      <BooleanInput label="Is the last step success?" source="result" />
      <NextActionInput source="nextStep" candidateid={id} fullWidth />
      <NextActionDateInput source="nextStepDate" fullWidth />
    </SimpleForm>
  );
};
