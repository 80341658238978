import {
  BooleanInput,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { Grid } from "@mui/material";

export const HolidayCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextInput source="name" fullWidth validate={required()} />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="event" fullWidth validate={required()} />
          </Grid>
        </Grid>

        <DateInput source="startDate" validate={required()} />
        <DateInput source="endDate" validate={required()} />
        <BooleanInput source="isRepeatable" />
      </SimpleForm>
    </Create>
  );
};
