import sex from "./sex";

export const getCurrentSelectedClientName = () => {
  let selectedClient = localStorage.getItem("client");
  var clientName = "";
  if (selectedClient !== null && selectedClient !== "null") {
    let clients = localStorage.getItem("clients");
    if (
      clients !== null &&
      clients !== "null" &&
      clients !== undefined &&
      clients !== "undefined"
    ) {
      let clientsParsed = JSON.parse(clients);
      clientsParsed.forEach((client) => {
        if (client.id === selectedClient) {
          clientName = client.name;
        }
      });
    }
  }

  return clientName;
};

export const getCurrentSelectedClientId = () => {
  let selectedClient = localStorage.getItem("client");
  var clientId = "";
  if (selectedClient !== null && selectedClient !== "null") {
    let clients = localStorage.getItem("clients");
    if (
      clients !== null &&
      clients !== "null" &&
      clients !== undefined &&
      clients !== "undefined"
    ) {
      let clientsParsed = JSON.parse(clients);
      clientsParsed.forEach((client) => {
        if (client.id === selectedClient) {
          clientId = client.id;
        }
      });
    }
  }

  return clientId;
};

export const getDisplaySex = (sexId) => {
  var returnSex = "_";
  Object.values(sex).forEach((element) => {
    if (element.id === sexId) {
      returnSex = element.id;
    }
  });

  return returnSex;
};

export const getDisplayCandidateInfo = (record) => {
  const jobLevelText =
    record.jobLevel && record.jobLevel.abbr ? record.jobLevel.abbr : "";
  const jobFamilyText = record.jobFamily ? record.jobFamily.name : "";

  return (jobLevelText + " " + jobFamilyText).trim();
};

export const getDisplayCandidateRate = (rate, record) => {
  if (rate) {
    var symbol = record.tempCurrencySymbol ?? "";
    if (record.currency && record.currency.symbol) {
      symbol = record.currency.symbol;
    }

    return (
      symbol +
      `${rate.toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })}`
    );
  }

  return "_";
};

export const getDisplayCandidateGrossSalary = (rate, record) => {
  if (rate) {
    var symbol = record.tempCurrencySymbol ?? "";
    if (record.grossSalaryCurrency && record.grossSalaryCurrency.symbol) {
      symbol = record.grossSalaryCurrency.symbol;
    }

    return (
      symbol +
      `${rate.toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })}`
    );
  }

  return "_";
};

export const getFormattedFileSize = (bytes) => {
  if (!bytes || bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const getCurrentDateAsString = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getYesterdayAsString = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getFileExtension = (fileName) => {
  const regex = /(?:\.([^.]+))?$/;
  const matches = regex.exec(fileName);
  return matches[1] || "";
};

const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

const convertDateToString = (value) => {
  // value is a `Date` object
  if (!(value instanceof Date) || isNaN(value.getDate())) return "";
  const pad = "00";
  const yyyy = value.getUTCFullYear().toString();
  const MM = (value.getUTCMonth() + 1).toString();
  const dd = value.getUTCDate().toString();
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

export const dateFormatter = (value) => {
  // null, undefined and empty string values should not go through dateFormatter
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === "") return "";
  if (value instanceof Date) return convertDateToString(value);
  // Valid dates should not be converted
  if (dateFormatRegex.test(value)) return value;

  return convertDateToString(new Date(value));
};
