import {
  DateField,
  List,
  TextField,
  EditButton,
  FunctionField,
  DeleteWithConfirmButton,
} from "react-admin";
import { useMemo } from "react";
import AssignClientButton from "../Candidate/AssignClientButton";
import MyDatagrid from "../MyDatagrid";
import {
  getCurrentSelectedClientName,
  getDisplayCandidateInfo,
  getDisplayCandidateGrossSalary,
} from "../../Helpers";
import { Box } from "@mui/material";
import CircleImageField from "../CircleImageField";

const MyDataGridValue = (props) => {
  return (
    <MyDatagrid bulkActionButtons={false} {...props}>
      <CircleImageField
        source="avatar.src"
        title="Avatar"
        width="40px"
        label="Avatar"
        sortable={false}
      />
      <FunctionField
        label="Name"
        source="name"
        render={(record) => (
          <Box
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            {`${record.name} \n`}
            <span style={{ fontSize: "11px" }}>
              {getDisplayCandidateInfo(record)}
            </span>
          </Box>
        )}
      />

      <TextField source="gender.name" label="Gender" sortable={false} />

      <DateField
        source="availableFrom"
        sortable={false}
        options={{ timeZone: "UTC" }}
      />

      <FunctionField
        render={(record) =>
          record.client === undefined ? <EditButton /> : <></>
        }
      />
      <FunctionField
        render={(record) => (
          <>
            <AssignClientButton
              record={{ record }}
              disabled={record.alreadyHired}
            />
            <DeleteWithConfirmButton
              width="100px"
              confirmTitle="Warning"
              confirmContent="Do you want to delete this candidate?"
            />
          </>
        )}
      />
    </MyDatagrid>
  );
};

const NoteInfo = () => {
  return (
    <>
      <div>
        AKA: <TextField source="alias" label="AKA" sortable={false} />
      </div>
      <div>
        English:{" "}
        <FunctionField
          label="English"
          render={(record) =>
            record.english ? record.english.name ?? record.tempEnglish : "_"
          }
          sortable={false}
        />
      </div>
      <div>
        Gross Salary:{" "}
        <FunctionField
          sortable={false}
          label="GrossSalary"
          render={(record) =>
            getDisplayCandidateGrossSalary(record.grossSalary, record)
          }
        />
      </div>
      <div>
        Source:{" "}
        <FunctionField
          label="Source"
          render={(record) =>
            record.source ? record.source.name ?? record.tempSource : "_"
          }
          sortable={false}
        />
      </div>
      <div>
        Onboard Rate:{" "}
        <FunctionField
          label="Onboard Rate"
          render={(record) =>
            record.source ? record.source.onboardRate ?? "_" : "_"
          }
          sortable={false}
        />
      </div>
    </>
  );
};

const CandidateTitle = () => {
  const selectedClientName = useMemo(() => getCurrentSelectedClientName(), []);
  const clientName = selectedClientName
    ? `Non-assigned Candidate list filtered by ${selectedClientName}`
    : "Non-assigned Candidate list";
  return <span>{clientName}</span>;
};

export const NonAssignedList = () => {
  return (
    <List title={<CandidateTitle />} sort={{ field: "name", order: "ASC" }}>
      <MyDataGridValue expand={<NoteInfo />} isRowExpandable={(row) => true} />
    </List>
  );
};
