import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  SelectInput,
  required,
  ReferenceInput,
  PasswordInput,
} from "react-admin";
import roles from "../../providers/roles";
import { useWatch } from "react-hook-form";
import { Grid } from "@mui/material";

const ClientInput = (props) => {
  const role = useWatch({ name: "role" });
  return role !== roles.SuperAdmin ? (
    <ReferenceInput
      label="Client"
      source="client.id"
      reference="clients"
      {...props}
    >
      <SelectInput
        label="Client"
        optionText="name"
        validate={required()}
        fullWidth
      />
    </ReferenceInput>
  ) : (
    <div />
  );
};

const validateEmail = (email) => {
  var emailCheck =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  return emailCheck.test(email);
};

const validateUserCreation = (values) => {
  const errors = {};
  if (!values.email || !validateEmail(values.email)) {
    errors.email = "Please input a valid email address";
  }

  if (!values.firstName) {
    errors.firstName = "Please input a valid First Name";
  }

  if (!values.lastName) {
    errors.lastName = "Please input a valid Last Name";
  }

  if (!values.password || values.password.length < 6) {
    errors.password = "Please input a valid password (minimum 6 characters)";
  }

  if (!values.role) {
    errors.role = "Please select a valid Role value";
  }
  if (values.role !== roles.SuperAdmin && !values.client.id) {
    errors.client = {
      id: "Please select a client",
    };
  }
  return errors;
};

export const UserCreate = () => {
  return (
    <Create>
      <SimpleForm validate={validateUserCreation}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextInput
              label="First Name"
              source="firstName"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label="Last Name"
              source="lastName"
              validate={required()}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextInput
              label="User email"
              source="email"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <PasswordInput
              label="Password"
              source="password"
              validate={required}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ClientInput fullWidth />
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              source="role"
              choices={Object.values(roles).map(function (element) {
                return { id: element, name: element };
              })}
              validate={required()}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ImageInput source="avatar" label="Avatar" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
