import {
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  useGetList,
  FileInput,
  FileField,
  useRecordContext,
  required,
} from "react-admin";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { getFileExtension, dateFormatter } from "../../Helpers";

const CurrencyInput = (props) => {
  const { data, isLoading } = useGetList("currencies", {}, {});
  const choices = data !== null && data !== undefined ? data : [];
  return (
    <SelectInput
      source="currency"
      choices={choices}
      disabled={isLoading}
      {...props}
    />
  );
};

const EditBillingTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `Billing ${record.documentId}` : ""}</span>;
};

const BillingFileInput = () => {
  const { setValue } = useFormContext();
  const useChangeInput = (input) => {
    if (input && input.name) {
      const extension = getFileExtension(input.name);
      if (extension) {
        setValue("documentType", extension);
        return;
      }
    }

    setValue("documentType", "");
  };

  return (
    <FileInput
      source="downloadFile"
      label="Download File"
      onChange={useChangeInput}
      validate={required()}
    >
      <FileField source="src" title="title" />
    </FileInput>
  );
};

export const BillingEdit = () => (
  <Edit title={<EditBillingTitle />}>
    <SimpleForm>
      <ReferenceInput label="Client" source="client.id" reference="clients">
        <SelectInput label="Client" optionText="name" disabled />
      </ReferenceInput>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <DateInput
            source="document"
            fullWidth
            validate={required()}
            format={dateFormatter}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            source="documentId"
            label="Document ID"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="documentType" fullWidth validate={required()} />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <NumberInput source="billAmount" fullWidth validate={required()} />
        </Grid>
        <Grid item xs={4}>
          <CurrencyInput fullWidth validate={required()} />
        </Grid>
      </Grid>

      <BillingFileInput />
    </SimpleForm>
  </Edit>
);
