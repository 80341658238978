import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
} from "react-admin";

export const SourceEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="name" fullWidth validate={required()} />
      <NumberInput source="onboardRate" fullWidth />
    </SimpleForm>
  </Edit>
);
