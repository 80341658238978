import {
  BooleanInput,
  DateInput,
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  useRecordContext,
  FileInput,
  FileField,
  NumberInput,
  useGetList,
} from "react-admin";
import { Grid } from "@mui/material";
import { useWatch, useFormContext } from "react-hook-form";
import dataProvider from "../../providers/dataProvider";
import { useLogout } from "react-admin";
import { dateFormatter } from "../../Helpers";

const JobFamilyInput = (props) => {
  const client = useWatch({ name: "client" });
  const { data, isLoading } = useGetList(
    "jobFamilies",
    { filter: { clientId: client ? client.id : "" } },
    { enabled: !!client }
  );
  return (
    <SelectArrayInput choices={data ?? []} disabled={isLoading} {...props} />
  );
};

const JobLevelInput = (props) => {
  const client = useWatch({ name: "client" });
  const { data, isLoading } = useGetList(
    "jobLevels",
    { filter: { clientId: client ? client.id : "" } },
    { enabled: !!client }
  );
  return (
    <SelectArrayInput choices={data ?? []} disabled={isLoading} {...props} />
  );
};

const SkillTestingInput = (props) => {
  const client = useWatch({ name: "client" });
  const { data, isLoading } = useGetList(
    "skillTestings",
    { filter: { clientId: client ? client.id : "" } },
    { enabled: !!client }
  );
  return (
    <SelectArrayInput choices={data ?? []} disabled={isLoading} {...props} />
  );
};

const EnglishInput = (props) => {
  const { data, isLoading } = useGetList(
    "english",
    { sort: { name: "ASC" } },
    {}
  );
  return (
    <SelectArrayInput choices={data ?? []} disabled={isLoading} {...props} />
  );
};

const HiringManagerInput = (props) => {
  const record = useRecordContext();
  return (
    <ReferenceInput
      label="Hiring Manager"
      source="hiringManager.id"
      reference="users"
      filter={{ clientId: record.client.id }}
      {...props}
    >
      <SelectInput
        label="User"
        optionText="username"
        validate={required()}
        fullWidth
      />
    </ReferenceInput>
  );
};

const RateInput = (props) => {
  const jobFamilies = useWatch({ name: "jobFamily" });
  const jobLevels = useWatch({ name: "jobLevel" });
  const client = useWatch({ name: "client" });
  const { setValue } = useFormContext();
  const logout = useLogout();

  if (client && jobFamilies && jobLevels) {
    const postData = {
      clientId: client.id,
      jobFamilies: jobFamilies ?? [],
      jobLevels: jobLevels ?? [],
    };
    dataProvider
      .create("requisitions/getRateForRequisition", { data: postData })
      .then((data) => {
        if (data.data.minRate) {
          setValue("minRate", data.data.minRate);
        }

        if (data.data.maxRate) {
          setValue("maxRate", data.data.maxRate);
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  }

  return (
    <Grid container spacing={1} style={{ width: "50%" }}>
      <Grid item xs={4}>
        <NumberInput source="minRate" disabled fullWidth />
      </Grid>
      <Grid item xs={4}>
        <NumberInput source="maxRate" disabled fullWidth />
      </Grid>
    </Grid>
  );
};

const RequisitionTitle = () => {
  const record = useRecordContext();
  return (
    <span>{record ? `${record.jobTitle} - ${record.client.name}` : ""}</span>
  );
};

export const RequisitionEdit = () => (
  <Edit title={<RequisitionTitle />}>
    <SimpleForm>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <ReferenceInput label="Client" source="client.id" reference="clients">
            <SelectInput label="Client" optionText="name" disabled fullWidth />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <DateInput
            source="openDate"
            fullWidth
            validate={required()}
            format={dateFormatter}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextInput source="jobTitle" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={4}>
          <JobFamilyInput source="jobFamily" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={4}>
          <JobLevelInput source="jobLevel" validate={required()} fullWidth />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <HiringManagerInput fullWidth />
        </Grid>
        <Grid item xs={4}>
          {" "}
          <SkillTestingInput
            source="skillTesting"
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <EnglishInput source="english" fullWidth />
        </Grid>
      </Grid>

      <RateInput />
      <BooleanInput source="adhocRateOffers" />
      <FileInput source="descriptionFile" label="Description file">
        <FileField source="src" title="title" />
      </FileInput>
      <BooleanInput source="approved" />
    </SimpleForm>
  </Edit>
);
