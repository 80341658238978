import {
  DateInput,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  FileInput,
  FileField,
  useRecordContext,
  required,
} from "react-admin";
import { Grid } from "@mui/material";
import { dateFormatter } from "../../Helpers";

const EditTimesheetTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.document}` : ""}</span>;
};

export const TimesheetEdit = () => (
  <Edit title={<EditTimesheetTitle />}>
    <SimpleForm>
      <ReferenceInput label="Client" source="client.id" reference="clients">
        <SelectInput label="Client" optionText="name" disabled />
      </ReferenceInput>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextInput source="document" fullWidth validate={required()} />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <DateInput
            source="fromDate"
            fullWidth
            validate={required()}
            format={dateFormatter}
          />
        </Grid>
        <Grid item xs={4}>
          <DateInput
            source="toDate"
            fullWidth
            validate={required()}
            format={dateFormatter}
          />
        </Grid>
      </Grid>

      <FileInput
        source="downloadFile"
        label="Download File"
        validate={required()}
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);
