import {
  Create,
  SimpleForm,
  TextInput,
  required,
  NumberInput,
} from "react-admin";

export const SourceCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" fullWidth validate={required()} />
      <NumberInput source="onboardRate" fullWidth />
    </SimpleForm>
  </Create>
);
