const sex = {
  Male: {
    id: "M",
    name: "Male",
  },
  Female: {
    id: "F",
    name: "Female",
  },
  Unknown: {
    id: "_",
    name: "Unknown",
  },
};

export default sex;