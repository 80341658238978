import {
  DateField,
  List,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  useGetList,
  SelectInput,
  Filter,
  FunctionField,
  TextInput,
  useRecordContext,
} from "react-admin";
// import AssignClientButton from "./AssignClientButton";
import SetStatusButton from "../Candidate/SetStatusButton";
import MyDatagrid from "../MyDatagrid";
import {
  getCurrentSelectedClientName,
  getDisplayCandidateInfo,
  getDisplayCandidateRate,
  getDisplayCandidateGrossSalary,
} from "../../Helpers";
import { Box } from "@mui/material";
import CircleImageField from "../CircleImageField";
import { useEffect, useState } from "react";
import dataProvider from "../../providers/dataProvider";
import { useLogout } from "react-admin";
import { SendEmailButton } from "./SendEmailButton";

const ClientFilters = (props) => {
  const [actions, setActions] = useState([]);
  const logout = useLogout();
  useEffect(() => {
    const filterInfo = { filter: { isAfterHired: true } };
    dataProvider
      .getList("actions", filterInfo)
      .then((data) => {
        setActions(data.data);
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  }, []);

  const { data, isLoading } = useGetList("clients");
  return (
    <Filter {...props}>
      <SelectInput
        source="client"
        choices={data ?? []}
        isLoading={isLoading}
        alwaysOn
      />
      <SelectInput source="lastStep" choices={actions} alwaysOn />
      <TextInput label="Search by name" source="q" alwaysOn />
    </Filter>
  );
};

const NoteInfo = () => {
  return (
    <>
      <div>
        AKA: <CustomTextField source="alias" label="AKA" sortable={false} />
      </div>
      <div>
        English:{" "}
        <CustomTextField
          source="english.name"
          label="English"
          sortable={false}
        />
      </div>
      <div>
        Gross Salary:{" "}
        <CustomFunctionField
          sortable={false}
          label="Gross Salary"
          render={(record) =>
            getDisplayCandidateGrossSalary(record.grossSalary, record)
          }
        />
      </div>
      <div>
        Source:{" "}
        <CustomFunctionField
          label="Source"
          render={(record) => (record.source ? record.source.name : "_")}
          sortable={false}
        />
      </div>
      <div>
        Onboard Rate:{" "}
        <CustomFunctionField
          label="Onboard Rate"
          render={(record) =>
            record.source ? record.source.onboardRate ?? "_" : "_"
          }
          sortable={false}
        />
      </div>
      <div>
        Note:{" "}
        <CustomFunctionField
          label="Note"
          render={(record) =>
            record.note && record.note !== "" ? (
              <TextField source="note" component="pre" />
            ) : (
              "_"
            )
          }
          sortable={false}
        />
      </div>
    </>
  );
};

const getTextColor = (record) => {
  if (!record.nextStepDate) {
    return "black";
  }
  const now = new Date();

  const msBetweenDates = Math.abs(
    new Date(record.nextStepDate).getTime() - now.getTime()
  );
  const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
  const color = hoursBetweenDates <= 24 ? "red" : "black";
  return color;
};

const CustomFunctionField = (props) => {
  const record = useRecordContext();
  const color = getTextColor(record);
  return <FunctionField sx={{ color }} {...props} />;
};

const CustomTextField = (props) => {
  const record = useRecordContext();
  const color = getTextColor(record);
  return <TextField sx={{ color }} {...props} />;
};

// const CustomReferrenceField = (props) => {
//   const record = useRecordContext();
//   const color = getTextColor(record);
//   return <ReferenceField sx={{ color }} {...props} />;
// };

const CustomDateField = (props) => {
  const record = useRecordContext();
  const color = getTextColor(record);
  return <DateField sx={{ color }} {...props} />;
};

const MyDataGridValue = (props) => {
  return (
    <MyDatagrid bulkActionButtons={false} {...props}>
      <CircleImageField
        source="avatar.src"
        title="Avatar"
        width="40px"
        label="Avatar"
        sortable={false}
      />
      <CustomFunctionField
        label="Name"
        source="name"
        render={(record) => (
          <Box
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            {`${record.name} \n`}
            <span style={{ fontSize: "11px" }}>
              {getDisplayCandidateInfo(record)}
            </span>
          </Box>
        )}
      />

      <CustomTextField source="gender.name" label="Gender" sortable={false} />
      {/* <CustomReferrenceField
        source="gender"
        reference="Genders"
        label="Sex"
        sortable={false}
      >
        <CustomTextField source="name" />
      </CustomReferrenceField> */}
      <CustomDateField
        source="availableFrom"
        sortable={false}
        options={{ timeZone: "UTC" }}
      />

      <CustomFunctionField
        sortable={false}
        label="Rate"
        render={(record) => getDisplayCandidateRate(record.rate, record)}
      />

      <CustomFunctionField
        sortable={false}
        label="Client name"
        render={(record) => (record.client ? record.client.name : "_")}
      />

      <CustomFunctionField
        sortable={false}
        label="Last Step"
        render={(record) => (record.lastStep ? record.lastStep.name : "_")}
      />

      <CustomFunctionField
        sortable={false}
        label="Next Key Date"
        render={(record) => {
          if (record.nextStepDate) {
            return (
              <DateField source="nextStepDate" options={{ timeZone: "UTC" }} />
            );
          } else {
            return "_";
          }
        }}
      />
      <CustomFunctionField
        sortable={false}
        label="Next Step"
        // sx={{ color: getTextColor(props.record) }}
        render={(record) => (record.nextStep ? record.nextStep.name : "_")}
      />

      <EditButton />

      <FunctionField
        render={(record) => (
          <SetStatusButton record={{ record }} isAfterHired={true} />
        )}
      />

      <FunctionField
        render={(record) => <SendEmailButton label="Review Contract" />}
      />

      <DeleteWithConfirmButton
        width="100px"
        confirmTitle="Warning"
        confirmContent="Do you want to delete this candidate?"
      />
    </MyDatagrid>
  );
};

const CandidateTitle = () => {
  var clientName = "Manage list";
  var selectedClientName = getCurrentSelectedClientName();
  if (selectedClientName !== "") {
    clientName += " filtered by " + selectedClientName;
  }
  return <span>{clientName}</span>;
};

export const ManageList = () => (
  <List
    title={<CandidateTitle />}
    filters={<ClientFilters />}
    sort={{ field: "name", order: "ASC" }}
  >
    <MyDataGridValue expand={<NoteInfo />} isRowExpandable={(row) => true} />
  </List>
);
