import {
  BooleanInput,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  NumberInput,
  useGetList,
  SelectArrayInput,
} from "react-admin";
import { Grid } from "@mui/material";
import { useWatch, useFormContext } from "react-hook-form";
import dataProvider from "../../providers/dataProvider";
import { useLogout } from "react-admin";

const toChoicesUser = (items) => {
  return items.map((item) => ({ id: item.id, name: item.username }));
};

const JobFamilyInput = (props) => {
  const client = useWatch({ name: "client" });
  const { data, isLoading } = useGetList(
    "jobFamilies",
    { filter: { clientId: client ? client.id : "" } },
    { enabled: !!client }
  );
  return (
    <SelectArrayInput choices={data ?? []} disabled={isLoading} {...props} />
  );
};

const JobLevelInput = (props) => {
  const client = useWatch({ name: "client" });
  const { data, isLoading } = useGetList(
    "jobLevels",
    { filter: { clientId: client ? client.id : "" } },
    { enabled: !!client }
  );
  return (
    <SelectArrayInput choices={data ?? []} disabled={isLoading} {...props} />
  );
};

const SkillTestingInput = (props) => {
  const client = useWatch({ name: "client" });
  const { data, isLoading } = useGetList(
    "skillTestings",
    { filter: { clientId: client ? client.id : "" } },
    { enabled: !!client }
  );
  return (
    <SelectArrayInput choices={data ?? []} disabled={isLoading} {...props} />
  );
};

const EnglishInput = (props) => {
  const { data, isLoading } = useGetList(
    "english",
    { sort: { name: "ASC" } },
    {}
  );
  return (
    <SelectArrayInput choices={data ?? []} disabled={isLoading} {...props} />
  );
};

const HiringManagerInput = (props) => {
  const client = useWatch({ name: "client" });
  const { data: currentUserList, isLoading: isLoaddingCurrentUserList } =
    useGetList(
      "users",
      { filter: { clientId: client ? client.id : "" } },
      { enabled: !!client }
    );
  return (
    <SelectInput
      label="Hiring manager"
      disabled={isLoaddingCurrentUserList}
      choices={toChoicesUser(currentUserList ?? [])}
      {...props}
    />
  );
};

const RateInput = (props) => {
  const jobFamilies = useWatch({ name: "jobFamily" });
  const jobLevels = useWatch({ name: "jobLevel" });
  const client = useWatch({ name: "client" });
  const { setValue } = useFormContext();
  const logout = useLogout();

  if (client && jobFamilies && jobLevels) {
    const postData = {
      clientId: client.id,
      jobFamilies: jobFamilies ?? [],
      jobLevels: jobLevels ?? [],
    };
    dataProvider
      .create("requisitions/getRateForRequisition", { data: postData })
      .then((data) => {
        if (data.data.minRate) {
          setValue("minRate", data.data.minRate);
        }

        if (data.data.maxRate) {
          setValue("maxRate", data.data.maxRate);
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  }

  return (
    <Grid container spacing={1} style={{ width: "50%" }}>
      <Grid item xs={4}>
        <NumberInput source="minRate" disabled fullWidth />
      </Grid>
      <Grid item xs={4}>
        <NumberInput source="maxRate" disabled fullWidth />
      </Grid>
    </Grid>
  );
};

export const RequisitionCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ReferenceInput
              label="Client"
              source="client.id"
              reference="clients"
            >
              <SelectInput
                label="Client"
                optionText="name"
                validate={required()}
                fullWidth
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={4}>
            <DateInput source="openDate" validate={required()} fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextInput source="jobTitle" validate={required()} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <JobFamilyInput
              source="jobFamily"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <JobLevelInput source="jobLevel" validate={required()} fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <HiringManagerInput source="hiringManager" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <SkillTestingInput
              source="skillTesting"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <EnglishInput source="english" fullWidth validate={required()} />
          </Grid>
        </Grid>

        <RateInput />
        <BooleanInput source="adhocRateOffers" defaultValue={true} />
        <FileInput source="descriptionFile" label="Description file">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
