import { Create, SimpleForm, SelectInput, SelectArrayInput } from "react-admin";
import { ToolbarWithoutDelete } from "../ToolbarWithoutDelete";
import roles from "../../providers/rolesSelection";
import permissions from "../../providers/permissionsSelection";

export const RolePermissionsCreate = () => (
  <Create>
    <SimpleForm toolbar={<ToolbarWithoutDelete />}>
      <SelectInput source="role" choices={roles} fullWidth />
      <SelectArrayInput source="permissions" choices={permissions} fullWidth />
    </SimpleForm>
  </Create>
);
