import {
  Create,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  SelectArrayInput,
} from "react-admin";
import roles from "../../providers/rolesSelection";

export const ActionCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="name" fullWidth validate={required()} />
        <TextInput source="status" fullWidth validate={required()} />
        <TextInput source="actionButton" fullWidth />
        <TextInput source="description" fullWidth multiline />
        <BooleanInput source="isAfterHired" />
        <BooleanInput source="isClientAction" />
        {/* <SelectArrayInput source="emailPermission" choices={roles} fullWidth />
        <TextInput source="emailContent" fullWidth multiline />
        <TextInput source="emailRejectContent" fullWidth multiline /> */}
        <SelectArrayInput
          source="pushNotificationPermission"
          choices={roles}
          fullWidth
        />
        <TextInput source="pushNotificationContent" fullWidth multiline />
        <TextInput source="pushNotificationRejectContent" fullWidth multiline />
      </SimpleForm>
    </Create>
  );
};
