import { ImageField } from "react-admin";

const CircleImageField = (props) => {
  return (
    <ImageField
      sx={{
        width: 50,
        "& img": {
          maxWidth: 50,
          maxHeight: 50,
          objectFit: "cover!important",
          aspectRatio: 1,
          borderRadius: "50%",
          boxShadow: 2,
        },
      }}
      {...props}
    />
  );
};

export default CircleImageField;
