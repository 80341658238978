import { Edit, SimpleForm, TextInput, required } from "react-admin";
import { ToolbarWithoutDelete } from "../ToolbarWithoutDelete";

export const JobLevelEdit = () => (
  <Edit>
    <SimpleForm toolbar={<ToolbarWithoutDelete />}>
      <TextInput source="name" fullWidth validate={required()} />
      <TextInput source="abbr" fullWidth />
    </SimpleForm>
  </Edit>
);
