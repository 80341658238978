const { REACT_APP_SERVER_URL } = process.env;

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(REACT_APP_SERVER_URL + "/auth/login", {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({
        "Content-Type": "application/json",
        "Access-Control-Expose-Headers": "Content-Range",
      }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then((auth) => {
        if (auth.user.role !== "Super Admin") {
          throw new Error("Don't have permission");
        }
        localStorage.setItem("auth", JSON.stringify(auth));
      })
      .catch((message) => {
        throw new Error(message ?? "Network error");
      });
  },
  checkAuth: () =>
    localStorage.getItem("auth")
      ? Promise.resolve()
      : Promise.reject({ message: "Login required" }),
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, username, email } = JSON.parse(localStorage.getItem("auth"))[
        "user"
      ];
      return Promise.resolve({ id, username, email });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;
