import {
  Datagrid,
  DateField,
  List,
  FileField,
  TextField,
  FunctionField,
  Filter,
  useGetList,
  SelectInput,
  DeleteWithConfirmButton,
} from "react-admin";
import { getFormattedFileSize } from "../../Helpers";
import { useMemo } from "react";

const ClientFilters = (props) => {
  const { data, isLoading } = useGetList("clients");
  const memoizedData = useMemo(() => data ?? [], [data]);

  return (
    <Filter {...props}>
      <SelectInput
        source="client"
        choices={memoizedData}
        isLoading={isLoading}
        alwaysOn
      />
    </Filter>
  );
};

export const TimesheetList = () => (
  <List filters={<ClientFilters />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="client.name" />
      <TextField source="document" />
      <DateField source="fromDate" options={{ timeZone: "UTC" }} />
      <DateField source="toDate" options={{ timeZone: "UTC" }} />
      <FileField source="downloadFile.src" title="downloadFile.title" />
      <FunctionField
        sortable={false}
        label="Size"
        render={(record) => getFormattedFileSize(record.size)}
      />
      <DeleteWithConfirmButton
        width="100px"
        confirmTitle="Warning"
        confirmContent="Do you want to delete this timesheet?"
      />
    </Datagrid>
  </List>
);
