import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const CurrencyCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" fullWidth validate={required()} />
      <TextInput source="symbol" fullWidth validate={required()} />
    </SimpleForm>
  </Create>
);
