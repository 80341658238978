import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  SelectArrayInput,
  SelectInput,
  required,
  NumberInput,
  useGetList,
} from "react-admin";
import { Grid } from "@mui/material";
import { useState, useEffect, useMemo } from "react";

const toChoices = (items) => {
  return items.map((item) => ({ id: item.id, name: item.name }));
};

const toChoicesActions = (items) => {
  return items.map((item) => ({
    id: item.id,
    name: item.name + (item.isAfterHired ? " - After hired" : ""),
  }));
};

const ArraySelectInput = (props) => {
  const jobFamilies = props.jobfamilies ?? [];
  const jobLevels = props.joblevels ?? [];
  return (
    <ArrayInput {...props}>
      <SimpleFormIterator fullWidth inline>
        <SelectInput
          source="jobFamily"
          choices={toChoices(jobFamilies)}
          validate={required()}
        />
        <SelectInput
          source="jobLevel"
          choices={toChoices(jobLevels)}
          validate={required()}
        />
        <NumberInput source="rate" validate={required()} min={0} />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const JobFamilyInput = (props) => {
  const { data, isLoading } = useGetList("jobFamilies", {}, {});
  const choices = useMemo(
    () => (data !== null && data !== undefined ? data : []),
    [data]
  );
  const { onCreate } = props;
  useEffect(() => {
    if (onCreate) {
      onCreate(choices);
    }
  }, [choices, onCreate]);
  return (
    <SelectArrayInput
      source="jobFamily"
      choices={toChoices(choices)}
      disabled={isLoading}
      fullWidth
    />
  );
};

const JobLevelInput = (props) => {
  const { data, isLoading } = useGetList("jobLevels", {}, {});
  const choices = useMemo(
    () => (data !== null && data !== undefined ? data : []),
    [data]
  );
  const { onCreate } = props;
  useEffect(() => {
    if (onCreate) {
      onCreate(choices);
    }
  }, [choices, onCreate]);
  return (
    <SelectArrayInput
      source="jobLevel"
      choices={toChoices(choices)}
      disabled={isLoading}
      fullWidth
    />
  );
};

const SkillTestingInput = (props) => {
  const { data, isLoading } = useGetList("skillTestings", {}, {});
  const choices = data !== null && data !== undefined ? data : [];
  return (
    <SelectArrayInput
      source="skillTesting"
      choices={toChoices(choices)}
      disabled={isLoading}
      {...props}
    />
  );
};

const ActionsInput = (props) => {
  const { data, isLoading } = useGetList("actions", {}, {});
  const choices = data !== null && data !== undefined ? data : [];
  return (
    <SelectArrayInput
      source="actions"
      choices={toChoicesActions(choices)}
      disabled={isLoading}
      {...props}
    />
  );
};

const CurrencyInput = (props) => {
  const { data, isLoading } = useGetList("currencies", {}, {});
  const choices = data !== null && data !== undefined ? data : [];
  return (
    <SelectInput
      source="currency"
      choices={choices}
      disabled={isLoading}
      {...props}
    />
  );
};

export const ClientCreate = () => {
  const [jobFamilies, setJobFamilies] = useState([]);
  const [jobLevels, setJobLevels] = useState([]);
  return (
    <Create>
      <SimpleForm>
        <TextInput
          label="Client name"
          source="name"
          validate={required()}
          fullWidth
        />
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <JobFamilyInput fullWidth onCreate={setJobFamilies} />
          </Grid>
          <Grid item xs={4}>
            <JobLevelInput fullWidth onCreate={setJobLevels} />
          </Grid>
          <Grid item xs={4}>
            <SkillTestingInput fullWidth />
          </Grid>
          <Grid item xs={4}>
            <ActionsInput fullWidth />
          </Grid>
        </Grid>
        <ImageInput source="logo" label="Client logo" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <CurrencyInput validate={required()} />
        <ArraySelectInput
          source="rateMatrix"
          jobfamilies={jobFamilies}
          joblevels={jobLevels}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
