import { Card, CardContent, CardHeader } from "@mui/material";
import { Title, SimpleForm, SelectInput, useGetList } from "react-admin";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import dataProvider from "../providers/dataProvider";
import {
  getCurrentSelectedClientName,
  getCurrentSelectedClientId,
} from "../Helpers";

const ClientInput = (props) => {
  const { data, isLoading } = useGetList("clients");
  localStorage.setItem("clients", JSON.stringify(data));

  const { setValue } = useFormContext();

  useEffect(() => {
    if (isLoading) {
      setValue("client", "");
    } else {
      setValue("client", localStorage.getItem("client") ?? "");
    }
  }, [isLoading, setValue]);

  return (
    <SelectInput
      label="Filter Client"
      source="client"
      disabled={isLoading}
      choices={data ?? []}
      defaultValue={isLoading ? "" : localStorage.getItem("client") ?? ""}
      {...props}
    />
  );
};

export const Dashboard = () => {
  const [title, setTitle] = useState("Dashboard");
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const clientId = getCurrentSelectedClientId();
    if (clientId !== "") {
      dataProvider
        .getList("dashboard", {
          filter: { clientId },
        })
        .then((data) => {
          setDashboardData(data.data);
        });
    } else {
      setDashboardData(null);
    }
  }, [title]);

  useEffect(() => {
    setTimeout(() => {
      const currentName = getCurrentSelectedClientName();
      if (currentName !== "") {
        setTitle(currentName);
      }
    }, 1000);
  }, []);

  const postSave = (data) => {
    if (data.client) {
      localStorage.setItem("client", data.client);
    } else {
      localStorage.setItem("client", "");
    }

    const selectedClientName = getCurrentSelectedClientName();
    if (selectedClientName !== "") {
      setTitle(selectedClientName);
    } else {
      setTitle("Dashboard");
    }
  };
  return (
    <>
      <Title title={title} />
      <Card>
        <CardHeader title="Sibyl Admin Panel" />
        <CardContent>
          You can select a client to filter the rest of the admin panel
          <SimpleForm onSubmit={postSave}>
            <ClientInput fullWidth />
          </SimpleForm>
          {dashboardData !== null && <div></div>}
        </CardContent>
      </Card>
    </>
  );
};
