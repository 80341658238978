import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  SelectInput,
  required,
  ReferenceInput,
  useRecordContext,
} from "react-admin";
import roles from "../../providers/roles";
import { Grid } from "@mui/material";

const UserTitle = () => {
  const record = useRecordContext();
  return <span>User {record ? `${record.username}` : ""}</span>;
};

const ClientInput = () => {
  const record = useRecordContext();
  return record.role !== roles.SuperAdmin ? (
    <ReferenceInput label="Client" source="client.id" reference="clients">
      <SelectInput
        label="Client"
        optionText="name"
        validate={required()}
        fullWidth
      />
    </ReferenceInput>
  ) : null;
};

export const UserEdit = () => {
  return (
    <Edit title={<UserTitle />}>
      <SimpleForm>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextInput disabled label="User email" source="email" fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextInput
              label="First Name"
              source="firstName"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label="Last Name"
              source="lastName"
              validate={required()}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ClientInput />
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              source="role"
              choices={Object.values(roles).map(function (element) {
                return { id: element, name: element };
              })}
              validate={required()}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ImageInput source="avatar" label="Avatar" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
