import {
  List,
  TextField,
  ImageField,
  EditButton,
  Filter,
  TextInput,
} from "react-admin";
import MyDatagrid from "../MyDatagrid";

const Filters = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search by name" source="q" alwaysOn />
    </Filter>
  );
};

export const ClientList = () => (
  <List sort={{ field: "name", order: "ASC" }} filters={<Filters />}>
    <MyDatagrid rowClick="edit" bulkActionButtons={false}>
      <ImageField
        source="logo.src"
        title="Logo"
        width="100px"
        sx={{
          width: 100,
          "& img": {
            maxWidth: 50,
            maxHeight: 50,
            objectFit: "cover!important",
            aspectRatio: 1,
            borderRadius: "50%",
            boxShadow: 2,
          },
        }}
        label="Logo"
        sortable={false}
      />
      <TextField source="name" />
      <EditButton width="60px" />
    </MyDatagrid>
  </List>
);
