import {
  DateInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  FileInput,
  FileField,
  required,
} from "react-admin";
import { Grid } from "@mui/material";

export const TimesheetCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput
        label="Client"
        source="client.id"
        reference="clients"
        fullWidth
        validate={required()}
      >
        <SelectInput label="Client" optionText="name" />
      </ReferenceInput>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextInput source="document" fullWidth validate={required()} />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <DateInput source="fromDate" fullWidth validate={required()} />
        </Grid>
        <Grid item xs={4}>
          <DateInput source="toDate" fullWidth validate={required()} />
        </Grid>
      </Grid>

      <FileInput
        source="downloadFile"
        label="Download File"
        validate={required()}
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
