import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  TextField,
  FunctionField,
  EditButton,
  DeleteWithConfirmButton,
  Filter,
  TextInput,
} from "react-admin";
import {
  getCurrentSelectedClientName,
  getDisplayCandidateRate,
} from "../../Helpers";

const RequisitionTitle = () => {
  var clientName = "Requisition list";
  var selectedClientName = getCurrentSelectedClientName();
  if (selectedClientName !== "") {
    clientName += " filtered by " + selectedClientName;
  }
  return <span>{clientName}</span>;
};

const Filters = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search by Job Title" source="q" alwaysOn />
    </Filter>
  );
};

export const RequisitionList = () => {
  return (
    <List
      title={<RequisitionTitle />}
      sort={{ field: "createdAt", order: "DESC" }}
      filters={<Filters />}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="client.name" sortable={false} />
        <TextField source="jobTitle" sortable={false} />
        <DateField
          source="openDate"
          sortable={false}
          options={{ timeZone: "UTC" }}
        />
        <BooleanField source="adhocRateOffers" sortable={false} />
        <FunctionField
          sortable={false}
          label="Min Rate"
          render={(record) =>
            getDisplayCandidateRate(record.minRate, record.client)
          }
        />
        <FunctionField
          sortable={false}
          label="Max Rate"
          render={(record) =>
            getDisplayCandidateRate(record.maxRate, record.client)
          }
        />
        <BooleanField source="approved" sortable={false} />
        <DateField source="createdAt" />
        <EditButton />
        <DeleteWithConfirmButton
          width="100px"
          confirmTitle="Warning"
          confirmContent="Do you want to delete this requisition?"
        />
      </Datagrid>
    </List>
  );
};
