import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  DeleteWithConfirmButton,
} from "react-admin";

export const EnglishList = () => {
  const canDelete = (english) => {
    const totalRequisition = english.totalRequisition ?? 0;
    const totalCandidate = english.totalCandidate ?? 0;
    return totalRequisition === 0 && totalCandidate === 0;
  };
  const generateConfirmDeleteMessage = (english) => {
    const totalRequisition = english.totalRequisition ?? 0;
    const totalCandidate = english.totalCandidate ?? 0;
    if (totalRequisition > 0 || totalCandidate > 0) {
      var message = "";
      if (totalRequisition > 0) {
        message = `${totalRequisition} requisitions`;
      }

      if (totalCandidate > 0) {
        if (message === "") {
          message = `${totalCandidate} candidates`;
        } else {
          message = message + `, ${totalCandidate} candidates`;
        }
      }

      return `There are ${message} using this English level, are you sure you want to delete it?`;
    }
    return "Do you want to delete this English level?";
  };

  const generateMessageNotDelete = (english) => {
    const totalRequisition = english.totalRequisition ?? 0;
    const totalCandidate = english.totalCandidate ?? 0;
    if (totalRequisition > 0 || totalCandidate > 0) {
      var message = "";
      if (totalRequisition > 0) {
        message = `${totalRequisition} requisitions`;
      }

      if (totalCandidate > 0) {
        if (message === "") {
          message = `${totalCandidate} candidates`;
        } else {
          message = message + `, ${totalCandidate} candidates`;
        }
      }

      return message;
    }
    return "";
  };

  return (
    <List
      sort={{ field: "name", order: "ASC" }}
      queryOptions={{ meta: { forAdmin: true } }}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" />
        <FunctionField
          label="Usage"
          render={(record) => (
            <>
              {canDelete(record) ? (
                <DeleteWithConfirmButton
                  width="100px"
                  confirmTitle="Warning"
                  confirmContent={generateConfirmDeleteMessage(record)}
                />
              ) : (
                <span>{generateMessageNotDelete(record)}</span>
              )}
            </>
          )}
        />
      </Datagrid>
    </List>
  );
};
