import { DateField, List, TextField } from "react-admin";
import MyDatagrid from "../MyDatagrid";

export const LogList = () => (
  <List sort={{ field: "timestamp", order: "DESC" }}>
    <MyDatagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="User" source="user.username" width="200px" />
      <TextField source="activity" />
      <DateField source="timestamp" showTime width="200px" />
    </MyDatagrid>
  </List>
);
