import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  DeleteWithConfirmButton,
} from "react-admin";

export const JobLevelList = () => {
  const canDelete = (jobLevel) => {
    const totalRequisition = jobLevel.totalRequisition ?? 0;
    const totalCandidate = jobLevel.totalCandidate ?? 0;
    const totalClient = jobLevel.totalClient ?? 0;
    return totalClient === 0 && totalCandidate === 0 && totalRequisition === 0;
  };

  const generateConfirmDeleteMessage = (jobLevel) => {
    return "Do you want to delete this Job Level?";
  };

  const generateMessageNotDelete = (jobLevel) => {
    const totalRequisition = jobLevel.totalRequisition ?? 0;
    const totalCandidate = jobLevel.totalCandidate ?? 0;
    const totalClient = jobLevel.totalClient ?? 0;
    if (totalRequisition > 0 || totalCandidate > 0 || totalClient > 0) {
      var message = "";
      if (totalRequisition > 0) {
        message = `${totalRequisition} requisitions`;
      }

      if (totalCandidate > 0) {
        if (message === "") {
          message = `${totalCandidate} candidates`;
        } else {
          message = message + `, ${totalCandidate} candidates`;
        }
      }

      if (totalClient > 0) {
        if (message === "") {
          message = `${totalClient} clients`;
        } else {
          message = message + `, ${totalClient} clients`;
        }
      }

      return message;
    }
    return "";
  };

  return (
    <List
      sort={{ field: "name", order: "ASC" }}
      queryOptions={{ meta: { forAdmin: true } }}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" />
        <TextField source="abbr" />
        <FunctionField
          label="Usage"
          render={(record) => (
            <>
              {canDelete(record) ? (
                <DeleteWithConfirmButton
                  width="100px"
                  confirmTitle="Warning"
                  confirmContent={generateConfirmDeleteMessage(record)}
                />
              ) : (
                <span>{generateMessageNotDelete(record)}</span>
              )}
            </>
          )}
        />
      </Datagrid>
    </List>
  );
};
