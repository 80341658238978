import {
  Edit,
  SimpleForm,
  TextInput,
  useRecordContext,
  SelectArrayInput,
} from "react-admin";
import { ToolbarWithoutDelete } from "../ToolbarWithoutDelete";
import permissions from "../../providers/permissionsSelection";

const RolePermissionTitle = () => {
  const record = useRecordContext();
  return <span>Role {record ? `${record.role}` : ""}</span>;
};

export const RolePermissionsEdit = () => (
  <Edit title={<RolePermissionTitle />}>
    <SimpleForm toolbar={<ToolbarWithoutDelete />}>
      <TextInput source="role" disabled fullWidth />
      <SelectArrayInput source="permissions" choices={permissions} fullWidth />
    </SimpleForm>
  </Edit>
);
