import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  FunctionField,
} from "react-admin";
import { Box } from "@mui/material";

export const ActionList = () => (
  <List sort={{ field: "name", order: "ASC" }}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="status" sortable={false} />
      <TextField source="actionButton" sortable={false} />
      <FunctionField
        label="Description"
        source="description"
        render={(record) => (
          <Box
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            {`${record.description ?? ""}`}
          </Box>
        )}
      />
      <BooleanField source="isClientAction" sortable={false} />
      <BooleanField source="isAfterHired" sortable={false} />
    </Datagrid>
  </List>
);
