const permissions = [
  {
    id: "Create / Edit User",
    name: "Create / Edit User",
  },
  {
    id: "Assign Roles",
    name: "Assign Roles",
  },
  {
    id: "See Dashboard",
    name: "See Dashboard",
  },
  {
    id: "Access Billing",
    name: "Access Billing",
  },
  {
    id: "Timesheets",
    name: "Timesheets",
  },
  {
    id: "Requisition",
    name: "Requisition",
  },
  {
    id: "Recruit",
    name: "Recruit",
  },
  {
    id: "Manage Team",
    name: "Manage Team",
  },
  {
    id: "See Rates",
    name: "See Rates",
  },
];

export default permissions;
