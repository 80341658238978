import {
  DateInput,
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  NumberInput,
  useGetList,
  useRecordContext,
} from "react-admin";
import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { getCurrentDateAsString } from "../../Helpers";

const AssetTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.brand} - ${record.model} ` : ""}</span>;
};

const StaffInput = (props) => {
  const record = useRecordContext();
  var clientId = "";
  if (record.client && record.client.id) {
    clientId = record.client.id;
  } else if (record.client && typeof record.client === "string") {
    clientId = record.client;
  }
  const { data, isLoading } = useGetList("manages", {
    filter: { clientId: clientId },
  });
  return <SelectInput choices={data ?? []} disabled={isLoading} {...props} />;
};

const AssetDateInput = (props) => {
  const { setValue } = useFormContext();
  const onChangeAge = (input) => {
    const dateValue = input.target.value;
    const selectedDate = new Date(dateValue);
    const today = new Date();
    const diffTime = Math.abs(today - selectedDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    setValue("age", (diffDays / 365).toFixed(2));
  };

  return <DateInput onChange={onChangeAge} {...props} />;
};

export const AssetEdit = () => (
  <Edit title={<AssetTitle />}>
    <SimpleForm>
      <ReferenceInput label="Client" source="client" reference="clients">
        <SelectInput label="Client" optionText="name" fullWidth />
      </ReferenceInput>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextInput source="type" fullWidth validate={required()} />
        </Grid>
        <Grid item xs={4}>
          <AssetDateInput
            source="date"
            label="Date"
            fullWidth
            validate={required()}
            inputProps={{
              max: getCurrentDateAsString(),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput source="age" fullWidth validate={required()} disabled />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextInput source="brand" fullWidth validate={required()} />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="model" fullWidth validate={required()} />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="serialNumber" fullWidth validate={required()} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <StaffInput source="staff" fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);
