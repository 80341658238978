import { Button } from "react-admin";
import { Link } from "react-router-dom";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const SetStatusButton = ({ record, isAfterHired }) => {
  return (
    <Button
      component={Link}
      to={
        isAfterHired
          ? `/manage/setStatus/${record.record.id}/${
              record.record.lastStep ? record.record.lastStep.id : "none"
            }`
          : `/candidate/setStatus/${record.record.id}/${
              record.record.lastStep ? record.record.lastStep.id : "none"
            }`
      }
      label="Update Status"
    >
      {/* <KeyboardDoubleArrowRightIcon /> */}
    </Button>
  );
};

export default SetStatusButton;
