import {
  List,
  TextField,
  FunctionField,
  DeleteWithConfirmButton,
} from "react-admin";
import MyDatagrid from "../MyDatagrid";

export const JobFamilyList = () => {
  const canDelete = (jobFamily) => {
    const totalRequisition = jobFamily.totalRequisition ?? 0;
    const totalCandidate = jobFamily.totalCandidate ?? 0;
    const totalClient = jobFamily.totalClient ?? 0;

    return totalRequisition === 0 && totalCandidate === 0 && totalClient === 0;
  };

  const generateConfirmDeleteMessage = (jobFamily) => {
    return "Do you want to delete this Job Family?";
  };

  const generateMessageNotDelete = (jobFamily) => {
    const totalRequisition = jobFamily.totalRequisition ?? 0;
    const totalCandidate = jobFamily.totalCandidate ?? 0;
    const totalClient = jobFamily.totalClient ?? 0;
    if (totalRequisition > 0 || totalCandidate > 0 || totalClient > 0) {
      var message = "";
      if (totalRequisition > 0) {
        message = `${totalRequisition} requisitions`;
      }

      if (totalCandidate > 0) {
        if (message === "") {
          message = `${totalCandidate} candidates`;
        } else {
          message = message + `, ${totalCandidate} candidates`;
        }
      }

      if (totalClient > 0) {
        if (message === "") {
          message = `${totalClient} clients`;
        } else {
          message = message + `, ${totalClient} clients`;
        }
      }

      return message;
    }
    return "";
  };

  return (
    <List
      sort={{ field: "name", order: "ASC" }}
      queryOptions={{ meta: { forAdmin: true } }}
    >
      <MyDatagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" />
        <FunctionField
          label="Usage"
          render={(record) => (
            <>
              {canDelete(record) ? (
                <DeleteWithConfirmButton
                  width="100px"
                  confirmTitle="Warning"
                  confirmContent={generateConfirmDeleteMessage(record)}
                />
              ) : (
                <span>{generateMessageNotDelete(record)}</span>
              )}
            </>
          )}
        />
      </MyDatagrid>
    </List>
  );
};
