import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  Filter,
  SelectInput,
  useGetList,
  DeleteWithConfirmButton,
} from "react-admin";
import { useMemo } from "react";

const ClientFilters = (props) => {
  const { data, isLoading } = useGetList("clients");
  const memoizedData = useMemo(() => data ?? [], [data]);

  return (
    <Filter {...props}>
      <SelectInput
        source="client"
        choices={memoizedData}
        isLoading={isLoading}
        alwaysOn
      />
    </Filter>
  );
};

export const AssetList = () => (
  <List filters={<ClientFilters />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="type" />
      <DateField source="date" />
      <NumberField source="age" />
      <TextField source="brand" />
      <TextField source="model" />
      <TextField source="serialNumber" />
      <TextField source="client.name" />
      <TextField source="staff.name" />
      <DeleteWithConfirmButton
        width="100px"
        confirmTitle="Warning"
        confirmContent="Do you want to delete this asset record?"
      />
    </Datagrid>
  </List>
);
