import {
  Datagrid,
  DateField,
  List,
  FileField,
  TextField,
  FunctionField,
  Filter,
  useGetList,
  SelectInput,
  DeleteWithConfirmButton,
} from "react-admin";
import { getDisplayCandidateRate } from "../../Helpers";
import { useMemo } from "react";

const ClientFilters = (props) => {
  const { data, isLoading } = useGetList("clients");
  const memoizedData = useMemo(() => data ?? [], [data]);

  return (
    <Filter {...props}>
      <SelectInput
        source="client"
        choices={memoizedData}
        isLoading={isLoading}
        alwaysOn
      />
    </Filter>
  );
};

export const BillingList = () => (
  <List filters={<ClientFilters />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="client.name" />
      <DateField source="document" options={{ timeZone: "UTC" }} />
      <TextField source="documentId" label="Document ID" />
      <TextField source="documentType" />
      <FileField source="downloadFile.src" title="downloadFile.title" />
      <FunctionField
        sortable={false}
        label="Bill amount"
        render={(record) => getDisplayCandidateRate(record.billAmount, record)}
      />
      <DeleteWithConfirmButton
        width="100px"
        confirmTitle="Warning"
        confirmContent="Do you want to delete this billing?"
      />
    </Datagrid>
  </List>
);
