const roles = [
  {
    id: "Super Admin",
    name: "Super Admin",
  },
  {
    id: "Owner",
    name: "Owner",
  },
  {
    id: "Admin",
    name: "Admin",
  },
  {
    id: "Hiring Director",
    name: "Hiring Director",
  },
  {
    id: "Hiring Manager",
    name: "Hiring Manager",
  },
  {
    id: "Finance",
    name: "Finance",
  },
];

export default roles;
