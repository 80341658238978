import {
  SimpleForm,
  required,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  FormDataConsumer,
  BooleanInput,
  NumberInput,
} from "react-admin";
import { useParams } from "react-router-dom";
import dataProvider from "../../providers/dataProvider";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { getDisplayCandidateRate } from "../../Helpers";
import { useLogout } from "react-admin";

const toChoices = (items) => {
  return items.map((item) => {
    var descriptionInfo = item.client.name + " - " + item.jobTitle;
    if (item.minRate) {
      descriptionInfo +=
        ". Min: " +
        (item.client.currency ? item.client.currency.symbol : "") +
        item.minRate.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
    }
    if (item.maxRate) {
      descriptionInfo +=
        ". Max: " +
        (item.client.currency ? item.client.currency.symbol : "") +
        item.maxRate.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
    }

    if (item.adhocRateOffers) {
      descriptionInfo += ". Adhoc rate available.";
    }
    return {
      id: item.id,
      name: descriptionInfo,
      adhocRateOffers: item.adhocRateOffers,
    };
  });
};

const toChoicesDefaultValue = (items) => {
  return items;
};

const getRequisitionFromList = (id, requisitions) => {
  var returnData = null;
  requisitions.forEach((requisition) => {
    if (requisition.id === id) {
      returnData = requisition;
    }
  });

  return returnData;
};

const RequisitionInput = (props) => {
  let { candidateid } = props;
  const { setValue } = useFormContext();

  const [data, setData] = useState([]);
  const [choices, setChoices] = useState([]);
  const [defaultChoices, setDefaultChoices] = useState(null);
  const [isLoadingRequisition, setDefaultIsLoadingRequisition] = useState(true);
  const logout = useLogout();
  useEffect(() => {
    setDefaultIsLoadingRequisition(true);
    dataProvider
      .getList("candidates/getAppliedRequisitions", {
        filter: { candidateid: candidateid },
      })
      .then((data) => {
        setDefaultChoices(toChoicesDefaultValue(data.data));
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  }, []);

  useEffect(() => {
    if (!defaultChoices) {
      return;
    }
    dataProvider
      .getList("requisitions/getApprovedRequisitions", {
        filter: { approved: true, candidateid },
      })
      .then((data) => {
        setData(data.data);
        setChoices(toChoices(data.data));
        setValue("requisitions", defaultChoices);
        setTimeout(() => {
          setDefaultIsLoadingRequisition(false);
        }, 200);
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  }, [defaultChoices]);

  return (
    <ArrayInput source="requisitions" disabled={isLoadingRequisition}>
      <SimpleFormIterator fullWidth>
        <SelectInput
          source="requisition"
          choices={choices}
          validate={required()}
          disabled={isLoadingRequisition}
        />
        <div>{"\n"}</div>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            if (scopedFormData.requisition) {
              const requisition = getRequisitionFromList(
                scopedFormData.requisition,
                data
              );
              if (requisition && requisition.adhocRateOffers) {
                return (
                  <div>
                    <span>
                      Rate Sheet:{" "}
                      {getDisplayCandidateRate(
                        requisition.expectedRate,
                        requisition.client
                      )}
                    </span>
                    <BooleanInput source={getSource("adhocRateOffers")} />
                    {scopedFormData.adhocRateOffers ? (
                      <NumberInput
                        source={getSource("expectedRate")}
                        defaultValue={requisition.expectedRate}
                      />
                    ) : null}
                  </div>
                );
              } else {
                return (
                  <div>
                    <div>
                      Rate Sheet:{" "}
                      {getDisplayCandidateRate(
                        requisition.expectedRate,
                        requisition.client
                      )}
                    </div>
                    {/* <NumberInput
                      source={getSource("expectedRate")}
                      defaultValue={requisition.expectedRate}
                      disabled
                    /> */}
                  </div>
                );
              }
            } else {
              return <></>;
            }
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const CandidateAssignClient = (props) => {
  let { id, candidateid } = useParams();
  const logout = useLogout();
  const postSave = (data) => {
    const postData = {
      id,
      requisitions: data.requisitions,
    };
    dataProvider
      .create("candidates/assignCandidateToRequisition", { data: postData })
      .then(() => {
        window.location.href = "#/candidates";
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  };
  return (
    <SimpleForm onSubmit={postSave}>
      <RequisitionInput validate={required()} candidateid={candidateid} />
    </SimpleForm>
  );
};
