import {
  DateField,
  EmailField,
  List,
  TextField,
  DeleteWithConfirmButton,
  EditButton,
  TextInput,
  Filter,
} from "react-admin";
import MyDatagrid from "../MyDatagrid";
import CircleImageField from "../CircleImageField";

const Filters = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search by name" source="q" alwaysOn />
    </Filter>
  );
};

export const UserList = () => (
  <List sort={{ field: "username", order: "ASC" }} filters={<Filters />}>
    <MyDatagrid rowClick="edit" bulkActionButtons={false}>
      <CircleImageField
        source="avatar.src"
        title="Avatar"
        width="100px"
        label="Avatar"
        sortable={false}
      />
      <TextField source="username" />
      <EmailField source="email" width="200px" sortable={false} />
      <TextField source="role" width="200px" sortable={false} />
      <DateField source="date" width="100px" sortable={false} />
      <TextField source="client.name" width="300px" sortable={false} />
      <EditButton width="100px" />
      <DeleteWithConfirmButton
        width="100px"
        confirmTitle="Warning"
        confirmContent="Do you want to delete this user?"
      />
    </MyDatagrid>
  </List>
);
