import {
  DateField,
  List,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  FunctionField,
} from "react-admin";
import MyDatagrid from "../MyDatagrid";
import CircleImageField from "../CircleImageField";
import { Box } from "@mui/material";

export const CommentList = () => (
  <List>
    <MyDatagrid bulkActionButtons={false}>
      <CircleImageField
        source="user.avatar.src"
        title="User Avatar"
        width="40px"
        label="Avatar"
        sortable={false}
      />
      <TextField title="User" source="user.username" />
      <CircleImageField
        source="candidate.avatar.src"
        title="Candidate Avatar"
        width="40px"
        label="Avatar"
        sortable={false}
      />
      <TextField title="Candidate" source="candidate.name" />
      <FunctionField
        label="Content"
        source="content"
        render={(record) => (
          <Box
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            {`${record.content}`}
          </Box>
        )}
      />
      <DateField source="createdAt" showTime />
      <EditButton />
      <DeleteWithConfirmButton
        width="100px"
        confirmTitle="Warning"
        confirmContent="Do you want to delete this comment?"
      />
    </MyDatagrid>
  </List>
);
