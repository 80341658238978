import { Button, useRecordContext, useNotify, useLogout } from "react-admin";
import dataProvider from "../../providers/dataProvider";

export const SendEmailButton = (props) => {
  const record = useRecordContext();
  const notify = useNotify();
  const logout = useLogout();
  const handleClick = () => {
    const postData = {
      id: record.id,
    };
    dataProvider
      .create("manages/sendEmailContractReview", { data: postData })
      .then(() => {
        notify("Email sent");
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  };
  return <Button onClick={handleClick} {...props} />;
};
